import router from '@/config/pc_routes'
const bridge = {

}

bridge.mobile = function () {
    if(window.location.pathname=='/m/pages/index'){
        return;
    }
    let r = router.resolve(window.location.pathname)
    if(r.params.app_id){
        return window.location.href = '/m/pages/app/index?app_id=' + r.params.app_id
    }else{
        return window.location.href = '/m/'
    }
}

export default bridge