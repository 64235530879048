import { defineAsyncComponent } from "vue";
import loadingComponent from "@/views/_loading.vue";
import timeoutComponent from "@/views/_timeout.vue";

export const asyncComponent = (loader) => {
  return defineAsyncComponent({
    loader,
    loadingComponent: loadingComponent,
    errorComponent: timeoutComponent,
    delay: 400,
    timeout: 30000
  })
}