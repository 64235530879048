<template>
    <div style="flex: 1; margin: 50px; max-width: 550px;">
        <el-skeleton :rows="5" animated />
        <div style="margin-top: 20px;">
            <el-button type="primary" @click="reload">Reload</el-button>
        </div>
    </div>
</template>
<script>
export default{
    methods: {
        reload() {
            window.location.reload();
        }
    }
}
</script>