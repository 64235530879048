import { createWebHistory, createRouter } from "vue-router";
import { routeGuard } from "@/utils/keepguard.js";
import { asyncComponent } from "@/utils/lazyLoadView.js";

const appRouters = [
  {
    path: ':app_id/',
    component: ()=>import('@/views/App/Layout.vue'),
    children: [
      {
        name: 'app',
        path: '',
        component: ()=>import('@/views/App/Home.vue'),
      },
      {
        path: 'kiosk',
        name: 'app-kiosks',
        component: asyncComponent(()=>import('@/views/Entity/KioskScreen.vue')),
      },
      {
        name: "app-settings",
        path: 'settings',
        meta: { admin: true },
        component: asyncComponent(()=>import('@/views/App/AdminPanel.vue')),
        children: [
          {
            path: 'application',
            name: "app-settings-general",
            component: asyncComponent(()=>import('@/views/App/Setting.vue')),
          },
          {
            path: 'dataset',
            name: "app-settings-dataset",
            component: asyncComponent(()=>import('@/views/App/Dataset.vue')),
          },
          {
            path: 'aggregation',
            name: "app-settings-aggregation",
            component: asyncComponent(()=>import('@/views/App/Aggregation.vue')),
          },
          {
            path: 'dashboard',
            name: "app-settings-dashboard",
            component: asyncComponent(()=>import('@/views/App/Dashboard.vue')),
          },
          // app-settings-menus -> SiteMenuConfig.vue
          {
            path: 'menus',
            name: "app-settings-menus",
            component: asyncComponent(()=>import('@/views/App/SiteMenuConfig.vue')),
          },
          // app-settings-automation -> Automation.vue
          {
            path: 'automation',
            name: "app-settings-automation",
            component: asyncComponent(()=>import('@/views/App/Automation.vue')),
          },
        ]
      },
      {
        path: 'entities',
        name: 'app-entities',
        component: asyncComponent(()=>import('@/views/Entity/Admin/Overview/Entities.vue')),
      },
      {
        path: 'flow/',
        component: asyncComponent(()=>import('@/views/Flow/Layout.vue')),
        children: [
          {
            path: 'todo',
            name: 'flow-todo',
            component: asyncComponent(()=>import('@/views/Flow/Todo.vue')),
          },
          {
            path: 'create',
            name: 'flow-create',
            component: asyncComponent(()=>import('@/views/Flow/Create.vue')),
          },
          {
            path: 'transactors',
            name: 'flow-transactors',
            component: asyncComponent(()=>import('@/views/Flow/Transactors.vue')),
          },
          {
            path: 'cc',
            name: 'flow-cc',
            component: asyncComponent(()=>import('@/views/Flow/Cc.vue')),
          },
        ]
      },
      {
        path: 'ingress/:ingress_id',
        name: 'app-ingress',
        component: ()=>import('@/views/Entity/Ingress.vue'),
      },
      {
        path: 'dashboard/:dashboard_id',
        name: 'app-dashboard',
        component: ()=>import('@/views/Dashboard/Layout.vue'),
      }
    ]
  },
  {
    path: ':app_id/dashboard/:dashboard_id/edit',
    name: "app-settings-dashboard-detail",
    meta: { fullScreen: true },
    component: asyncComponent(()=>import('@/views/Dashboard/Admin/DashboardEditor.vue')),
  },
  {
    path: ':app_id/entity/:entity_id/edit/',
    component: asyncComponent(()=>import('@/views/Entity/Admin/Admin.vue')),
    meta: { fullScreen: true },
    children: [
      {
        name:"entity-edit",
        path: '',
        component: asyncComponent(()=>import('@/views/Entity/Admin/Editor.vue')),
      },
      {
        name:"entity-config",
        path: 'config',
        meta: { 'entityConfig': true},
        component: asyncComponent(()=>import('@/views/Entity/Admin/Config/Layout.vue')),
        children:[
          {
            name:"entity-config-data",
            path: 'data',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Config/Data.vue')),
          },
          {
            name:"entity-config-automate",
            path: 'automate',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Config/Automate.vue')),
          },
          {
            name:"entity-config-api",
            path: 'api',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Config/Api.vue')),
          },
          {
            name:"entity-config-webhook",
            path: 'webhook',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Config/Webhook.vue')),
          },
          {
            name:"entity-config-print",
            path: 'print',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Config/Print.vue')),
          },
          {
            name:"entity-config-submit",
            path: 'submit',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Config/Submit.vue')),
          },
          {
            name:"entity-config-button",
            path: 'button',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Config/Button.vue')),
          },
          {
            name:"entity-publish-ingress",
            path: 'ingress',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Publish/Private.vue')),
          },
          {
            name:"entity-publish-public",
            path: 'public',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Publish/Public.vue')),
          },
          {
            name: "entity-publish-kiosk",
            path: 'kiosk',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Publish/Device.vue')),
          },
          {
            name: "entity-publish-view",
            path: 'view',
            component: asyncComponent(()=>import('@/views/Entity/Admin/Publish/View.vue')),
          }
        ]
      },
      {
        name:"entity-process",
        path: 'process',
        component: asyncComponent(()=>import('@/views/Entity/Admin/Workflow.vue')),
      },
      {
        name:"entity-data",
        path: 'data',
        component: asyncComponent(()=>import('@/views/Entity/Admin/Data/Index.vue')),
      },
    ]
  },
  {
    path: ':app_id/entity/:entity_id/edit/print/:template_id',
    component: asyncComponent(()=>import('@/views/Entity/Admin/Print/ExcelTemplate.vue')),
    meta: { fullScreen: true },
  },
  {
    path: ':app_id/entity/:entity_id/edit/print2/:template_id',
    component: asyncComponent(()=>import('@/views/Entity/Admin/Print/Template.vue')),
    meta: { fullScreen: true },
  },
  {
    path: ':app_id/entity/:entity_id/edit/kiosk/:kiosk_id',
    component: asyncComponent(()=>import('@/views/Entity/Admin/Kiosk/KioskEditor.vue')),
    name: 'Entity.Admin.Publish.KioskFlowItem',
    meta: { fullScreen: true },
  },
]

const authRouters = [
  {
    path: 'passport',
    component: asyncComponent(()=>import('@/views/Auth/Passport.vue')),
    children: [
      {
        path: '',
        name: 'login',
        component: asyncComponent(()=>import('@/views/Auth/Login.vue')),
      },
      {
        path: 'signup',
        name: 'signup',
        component: asyncComponent(()=>import('@/views/Auth/Signup.vue')),
      },
      {
        path: 'employee-login',
        name: 'employee-login',
        component: asyncComponent(()=>import('@/views/Auth/EmployeeLogin.vue')),
      },
    ]
  },
  {
    path: 'verify-email',
    component: asyncComponent(()=>import('@/views/Auth/VerifyEmail.vue')),
  },
  {
    path: 'bind-email/:token',
    component: asyncComponent(()=>import('@/views/Auth/BindEmail.vue')),
  },
  {
    path: 'logout',
    name: 'logout',
    component: asyncComponent(()=>import('@/views/Auth/Logout.vue')),
  },
  {
    path: 'forgot-password',
    component: asyncComponent(()=>import('@/views/Auth/ForgotPassword.vue')),
  },
  {
    path: 'reset-password/:token',
    component: asyncComponent(()=>import('@/views/Auth/ResetPassword.vue')),
  },
]

const myRoutes = [
  {
    path: '',
    component: asyncComponent(()=>import('@/views/My/Layout.vue')),
    children: [
      {
        path: 'profile',
        name: 'my-profile',
        component: asyncComponent(()=>import('@/views/My/Profile.vue')),
      },
      {
        path: 'account',
        name: 'my-account',
        component: asyncComponent(()=>import('@/views/My/Account.vue')),
      }
    ]
  },
  {
    path: 'organizations',
    component: asyncComponent(()=>import('@/views/My/Organization.vue')),
  },
  {
    path: 'invitation/:id',
    component: asyncComponent(()=>import('@/views/My/Invitation.vue')),
  }
]

const tenantRouters = [
  {
    path: '',
    component: () => import("@/views/Tenant/Layout.vue"),
    children: [
      {
        path: '',
        name: 'tenantRoot',
        meta: { fullScreen: true },
        component: asyncComponent(()=>import('@/views/Tenant/TenantRoot.vue')),
      },
      {
        path: 'integrations',
        name: 'integrations',
        component: asyncComponent(()=>import('@/views/Integrations/Layout.vue')),
        children: [
          {
            path: 'templates',
            name: 'integrations-templates',
            component: asyncComponent(()=>import('@/views/Integrations/Templates/Index.vue')),
          },
          {
            path: 'hooklets',
            name: 'integrations-hooklets',
            component: asyncComponent(()=>import('@/views/Integrations/Hooklets/Index.vue')),
          },
          {
            path: 'hooklets/create',
            name: 'integrations-hooklets-create',
            component: asyncComponent(()=>import('@/views/Integrations/Hooklets/Create.vue')),
          },
          {
            path: 'connections',
            name: 'integrations-connections',
            children: [
              {
                path: '',
                name: 'integrations-connections',
                component: asyncComponent(()=>import('@/views/Integrations/Connection/Home.vue')),
              },
              // {
              //   path: 'create',
              //   component: asyncComponent(()=>import('@/views/Integrations/Connection/Create.vue')),
              // },
              // {
              //   path: 'create/:connector_uuid',
              //   component: asyncComponent(()=>import('@/views/Integrations/Connection/CreateByConnector.vue')),
              // },
              // {
              //   path: 'edit/:connection_id',
              //   component: asyncComponent(()=>import('@/views/Integrations/Connection/Edit.vue')),
              // },
            ]
          },
        ],
      },
      {
        path: 'the/:app/:path*',
        component: asyncComponent(()=>import('@/views/Container/EmbeddedApp.vue')),
      },
      {
        path: 'app',
        component: asyncComponent(()=>import('@/views/Container/App.vue')),
        children: appRouters,
      }
    ]
  },
  {
    path: '/admin',
    component: asyncComponent(()=>import('@/views/Admin/Layout.vue')),
    children: [
      {
        path: 'contact',
        component: asyncComponent(()=>import('@/views/Admin/Contact/Home.vue')),
      },
      {
        path: 'tenant',
        component: asyncComponent(()=>import('@/views/Admin/Tenant/Layout.vue')),
        children: [
          {
            path: 'general',
            component: asyncComponent(()=>import('@/views/Admin/Tenant/General.vue')),
          },
          {
            path: 'domain',
            component: asyncComponent(()=>import('@/views/Admin/Tenant/Domain.vue')),
          },
          {
            path: 'usage',
            component: asyncComponent(()=>import('@/views/Admin/Tenant/Usage.vue')),
          },
          {
            path: 'auth',
            component: asyncComponent(()=>import('@/views/Admin/Tenant/Auth.vue')),
          },
          {
            path: 'subscriptions',
            component: asyncComponent(()=>import('@/views/Admin/Tenant/Subscriptions.vue')),
          },
          {
            path: 'notify',
            component: asyncComponent(()=>import('@/views/Admin/Tenant/Notify.vue')),
          },
          {
            path: 'kiosk-group',
            component: asyncComponent(()=>import('@/views/Admin/Tenant/KioskGroup.vue')),
          },
          {
            path: 'billing',
            component: asyncComponent(()=>import('@/views/Admin/Tenant/Billing.vue')),
          },
          {
            path: 'api',
            component: asyncComponent(()=>import('@/views/Admin/Tenant/Api.vue')),
          },
        ]
      },
    ]
  },
]

const routes = [
  {
    path: '/auth',
    component: asyncComponent(()=>import('@/views/Auth/Layout.vue')),
    children: authRouters,
  },
  {
    path: '/',
    component: asyncComponent(()=>import('@/views/Container/User.vue')),
    children: [
      {
        path: 'home',
        name: 'home',
        component: asyncComponent(()=>import('@/views/My/Home.vue')),
      },
      {
        path: 'my',
        component: asyncComponent(()=>import('@/views/Container/My.vue')),
        children: myRoutes,
      },
      {
        path: '',
        component: asyncComponent(()=>import('@/views/Container/Tenant.vue')),
        children: tenantRouters,
      },
    ]
  },
  {
    path: '/app/:app_id/entity/:entity_id/edit/kiosk/:kiosk_id/preview',
    component: asyncComponent(()=>import('@/views/Entity/Admin/Kiosk/Preview.vue')),
    meta: { fullScreen: true },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/notFound.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(routeGuard)

router.beforeResolve((to, from, next) => {
  next();
  document.getElementById('app').style.visibility = 'visible';
  document.getElementById('mainloading').style.display = 'none';
});

export default router;
